define("ember-svg-jar/inlined/dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 24.6s2-3.8 6.5-7.9c5-4.5 12-5 17.1-15.7L37 13.4C31.8 24 25 24.6 19.8 29c-4.5 4-6.4 7.9-6.4 7.9L1 24.6z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M19.2 22.7a3.5 3.5 0 100-7 3.5 3.5 0 000 7z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M27.4 14a1.2 1.2 0 100-2.5 1.2 1.2 0 000 2.4zM11.2 27.1a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});