define("ember-svg-jar/inlined/layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 10l3.4 1.9c.8.5.8 1.6 0 2.1l-7.8 4.5c-1 .6-2.2.6-3.2 0L1.6 14c-.8-.5-.8-1.6 0-2.1l3.3-2\" stroke=\"#FDE995\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.4 12.5L1.6 8c-.8-.4-.8-1.6 0-2l7.8-4.5c1-.5 2.2-.5 3.2 0L20.4 6c.8.5.8 1.7 0 2.1l-7.8 4.5c-1 .6-2.2.6-3.2 0v0z\" stroke=\"#FDE995\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "20",
      "viewBox": "0 0 22 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});