define("ember-svg-jar/inlined/no-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M200.4 207.2v22.3m-11.1-11.1h22.3\" stroke=\"#8184FF\" stroke-width=\"4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M185.9 16.8v16.8m-8.4-8.4h16.8M10.4 153.4v16.8M2 161.8h16.8\" stroke=\"#FDE995\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M24.5 233a4 4 0 100-7.9 4 4 0 000 7.9zM245 14a7 7 0 100-14 7 7 0 000 14zm.5 236a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM20.5 74a6.1 6.1 0 100-12.2 6.1 6.1 0 000 12.2zm209.1 13.6a3.9 3.9 0 100-7.7 3.9 3.9 0 000 7.7zm-168.1-57a8.4 8.4 0 100-16.8 8.4 8.4 0 000 16.8zM127 213.8a88.8 88.8 0 100-177.6 88.8 88.8 0 000 177.6z\" fill=\"#0F0E25\"/><path d=\"M200.9 105.7L127 196l27.1-90.3h46.8z\" fill=\"#CDD2E5\"/><path d=\"M200.9 105.7H154l22-37.8 24.8 37.8z\" fill=\"#ECEFF8\"/><path d=\"M176 68l-21.9 37.7L127 67.9h49zm-49 0l-27.1 37.7-22-37.8H127zm0 128l-73.9-90.3H100l27 90.3z\" fill=\"#fff\"/><path d=\"M99.9 105.7H53.1L78 67.9l21.9 37.8z\" fill=\"#ECEFF8\"/><path d=\"M154.1 105.7L127 196l-27.1-90.3h54.2z\" fill=\"#DCE0EF\"/><path d=\"M154.1 105.7H99.9L127 67.9l27.1 37.8z\" fill=\"#ECEFF8\"/><path d=\"M100.1 150.3a29.6 29.6 0 11-41.7 42 29.6 29.6 0 0141.7-42z\" fill=\"#5E62ED\"/><path d=\"M97 147.7a29.6 29.6 0 01-41.5 41.5A29.6 29.6 0 0197 147.7z\" fill=\"#8184FF\"/><path d=\"M78.9 185a14 14 0 100-28 14 14 0 000 28zm9.8-23.9L69 181\" stroke=\"#fff\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "252",
      "height": "250",
      "viewBox": "0 0 252 250",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});