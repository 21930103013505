define("ember-svg-jar/inlined/instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 5.2C0 2.3 2.4 0 5.3 0h13.4C21.6 0 24 2.3 24 5.2V18c0 2.8-2.4 5.1-5.3 5.1H5.3c-2.9.1-5.3-2.1-5.3-5V5zM12 9a2.6 2.6 0 00-2.7 2.6c0 1.4 1.2 2.6 2.7 2.6 1.5 0 2.7-1.2 2.7-2.6 0-1.4-1.2-2.6-2.7-2.6zm-5.3 2.6C6.7 8.8 9 6.5 12 6.5s5.3 2.3 5.3 5.1c0 2.9-2.4 5.2-5.3 5.2-3 0-5.3-2.3-5.3-5.2zm12.6-5.8c.8 0 1.4-.5 1.4-1.3s-.6-1.3-1.4-1.3c-.8 0-1.3.5-1.3 1.3s.7 1.3 1.3 1.3z\" fill=\"url(#a)\"/><defs><linearGradient id=\"a\" x1=\".4\" y1=\"11.6\" x2=\"24\" y2=\"11.6\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});