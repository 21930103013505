define("ember-svg-jar/inlined/coinmarketcap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.5 14.3c-.4.3-.9.4-1.3.1-.4-.2-.7-.9-.7-1.7V10c0-1.2-.5-2.1-1.3-2.3-1.4-.5-2.5 1.3-3 2l-2.4 4v-5c0-1.1-.4-1.8-1.1-2-.5-.2-1.2-.1-1.8 1l-5.6 8.9A10 10 0 012 12c0-5.4 4.4-9.8 9.7-9.8 5.3 0 9.6 4.4 9.6 9.8 0 1-.2 2-.9 2.3zm3-2.3A12 12 0 0011.9 0C5.3 0 0 5.4 0 12a12 12 0 0011.8 12c3 0 5.8-1.1 8-3.2.4-.4.5-1.1 0-1.5a1 1 0 00-1.5-.1 9.6 9.6 0 01-13.7-.6l5-8.1v3.7c0 1.8.7 2.4 1.3 2.6.6.1 1.4 0 2.4-1.5l2.8-4.5.2-.4v2.3c0 1.7.7 3 1.8 3.7 1 .6 2.4.5 3.5-.1 1.4-1 2-2.5 2-4.3z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});