define("ember-svg-jar/inlined/website-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.8 15.3H7.6c-.3-2.2-.3-4.4 0-6.6h9.2a25 25 0 010 6.6zM16.5 7l-.1-.4c-.4-1.6-.9-3-1.8-4.4-.4-.6-.8-1.1-1.4-1.5-.7-.3-1.3-.3-1.9 0-.5.3-.9.6-1.2 1A13 13 0 007.9 7h8.6zM10 22c.3.4.6.8 1 1 .8.7 1.7.7 2.5 0a7 7 0 001.2-1.3c.7-1 1.2-2.3 1.5-3.5l.3-1.3H8a13 13 0 002.1 5.2zm8.5-6.7h5c.3 0 .3 0 .3-.2.5-2 .5-4.1 0-6.2 0-.2 0-.2-.3-.2h-5.1c.3 2.2.3 4.4 0 6.6h.1zM6 8.7H1c-.2 0-.3 0-.3.2-.5 2-.5 4.1 0 6.2 0 .2 0 .2.3.2h5c-.3-2.2-.3-4.4 0-6.6h-.1zM18.2 7h5c-.7-2.7-5.3-6.7-8.3-7 2 2 2.7 4.5 3.3 7zm-17 0h5c.6-2.6 1.4-5 3.3-7C6.5.3 2 4.2 1.2 7zm22 10h-5c-.3 1.3-.6 2.5-1.2 3.7a9.7 9.7 0 01-2 3.3c3-.2 7.6-4.3 8.3-7zM4.6 21.5c1.3 1.2 4 2.6 4.9 2.5-2-2-2.7-4.5-3.3-7h-5c.8 1.8 2 3.3 3.4 4.5z\" fill=\"#56568F\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});