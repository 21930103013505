define("ember-svg-jar/inlined/card-coin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 21.7H3.4c-1.3 0-2.4-1-2.4-2.3v-16C1 2 2 1 3.4 1h27.2C32 1 33 2 33 3.4v16c0 1.3-1 2.3-2.4 2.3h-14\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M24.8 15.5H33V7.2h-8.2a4.1 4.1 0 00-4.1 4.2c0 2.3 1.8 4.1 4.1 4.1zm-.4-4.1h3.3m-22-5h4.8m-4.8 4.3h8.5m-2.9 11c2.9 0 5.2-1.4 5.2-3 0-1.7-2.3-3-5.2-3-3 0-5.3 1.3-5.3 3 0 1.6 2.3 3 5.3 3z\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16.5 18.7v3.6c0 .7-.5 1.5-1.5 2.1a8.3 8.3 0 01-7.5 0C6.5 23.8 6 23 6 22.3v-3.6\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "34",
      "height": "26",
      "viewBox": "0 0 34 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});