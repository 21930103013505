define("ember-svg-jar/inlined/certik", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M42.2 7.5l.3 1.6a40.4 40.4 0 01-.9 14 36 36 0 01-18.5 23.4l-.7.4-.8-.4A36 36 0 012 13.5c0-1.5.1-3 .3-4.4l.3-1.6a1 1 0 10-2-.3L.4 8.8a42.4 42.4 0 001 14.8 38 38 0 0019.4 24.6l1.2.7a1 1 0 001 0l1.2-.7a38 38 0 0020.7-34.8c0-1.5-.1-3-.4-4.6l-.2-1.6a1 1 0 10-2 .3z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.8 6.4L22.4 2l14.8 4.5a1 1 0 00.6-1.8L22.7 0a1 1 0 00-.6 0L7.3 4.6a1 1 0 10.5 1.8z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.7 7.8l20.8 40.7a1 1 0 001.7 0L36 24.7l8.2-17a1 1 0 00-1.8-.8l-8 17-11.9 22-20-39a1 1 0 10-1.7.9z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.6 26h23.2a1 1 0 100-2H10.6a1 1 0 100 2zm64.7-8.5H60.2a1 1 0 00-1 1v12a1 1 0 001 1h15v2h-15a3 3 0 01-3-3v-12a3 3 0 013-3h15v2zm28.3-2v2H89v6h11.6v2H89.1v6h14.5v2H87v-18h16.6z\" fill=\"#fff\"/><path d=\"M133.5 18.5v5.3a3 3 0 01-3 3h-2.8l5.7 6.8h-2.7l-5.7-6.8h-7.7v6.8h-2V15.5h15.1a3 3 0 013 3zm-15.2 6.3h12.1a1 1 0 001-1v-5.3a1 1 0 00-.3-.7.9.9 0 00-.7-.3h-12a1 1 0 00-1 1v5.3a1 1 0 00.2.7 1 1 0 00.7.3z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M144.3 15.4h18.1v2h-8v16.2h-2V17.5h-8.1v-2zm29.1 18.2V15.4h2v18.2h-2zm29.3-18.2h2.6l-7.5 9.1 7.6 9h-2.7l-6.7-8h-6v8h-2v-18h2v8h5.9l6.7-8z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "206",
      "height": "49",
      "viewBox": "0 0 206 49",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});