define("ember-svg-jar/inlined/tiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.7 9.7a2 2 0 000 2.7l-4.4 4.3a1 1 0 01-1.3 0L1.3 12a1 1 0 010-1.3l4.3-4.4c.8.7 2 .7 2.7 0 .8-.7.8-2 0-2.7l2.4-2.3a1 1 0 011.3 0L16.7 6c.4.3.4 1 0 1.3l-2.3 2.4a2 2 0 00-2.7 0h0zM8.3 6.3l3.4 3.4M5.4 11l1.5 1.6\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});