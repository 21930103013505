define("ember-svg-jar/inlined/money-circle-gradient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.6 8.4c0-1.5-1.2-2.7-2.6-2.7m0 0a2.6 2.6 0 000 5.3 2.6 2.6 0 010 5.2m0-10.4V4.3m0 11.9a2.6 2.6 0 01-2.6-2.6m2.6 2.6v1.5M21 11a10 10 0 11-20 0 10 10 0 0120 0z\" stroke=\"url(#a)\" stroke-width=\"1.5\" stroke-linecap=\"round\"/><defs><linearGradient id=\"a\" x1=\"1.3\" y1=\"11\" x2=\"21\" y2=\"11\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});