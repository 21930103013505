define("ember-svg-jar/inlined/world", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M28.8 5.6a52 52 0 0142.7-.3m-65.9 31c2.3-10 8-18.8 15.8-25m67.4 11a44.3 44.3 0 01-9.9 58.5M83.6 15c-1.5-1.7-3-3.3-4.7-4.7M4.7 45.5v.9a44 44 0 0016.7 34.4m7.4 5.7a51.1 51.1 0 0042.7-.1\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M86.4 23.3a4.6 4.6 0 100-9.3 4.6 4.6 0 000 9.3zM25.1 88.2a4.6 4.6 0 100-9.3 4.6 4.6 0 000 9.3zM5.2 45.5c2.3 0 4.2-2 4.2-4.6 0-2.6-2-4.6-4.2-4.6-2.3 0-4.2 2-4.2 4.6 0 2.6 1.9 4.6 4.2 4.6zM73 53.9c2.2 0 4-2 4-4.7 0-2.5-1.8-4.6-4-4.6-2.4 0-4.3 2-4.3 4.6 0 2.6 1.9 4.7 4.2 4.7zM32.5 42.8a4.6 4.6 0 100-9.3 4.6 4.6 0 000 9.3zm17.7-19.5a4.6 4.6 0 100-9.3 4.6 4.6 0 000 9.3zm25.5 64.9c2.3 0 4.2-2 4.2-4.6 0-2.6-1.9-4.7-4.2-4.7-2.3 0-4.2 2.1-4.2 4.7 0 2.5 1.9 4.6 4.2 4.6zm-.5-76.1c2.6 0 4.7-1.8 4.7-4.1s-2.1-4.2-4.7-4.2c-2.5 0-4.6 1.9-4.6 4.2 0 2.3 2 4.1 4.6 4.1zm-50.5.9c2.3 0 4.1-2 4.1-4.6 0-2.5-1.8-4.6-4.1-4.6s-4.2 2-4.2 4.6c0 2.6 1.9 4.7 4.2 4.7z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M35.3 33.5a57 57 0 0111.2-12m7.4-4.7a83.7 83.7 0 0116.7-7.4M24.6 79a68 68 0 016-37.2m44.6 3.7a87.1 87.1 0 0010.2-22.2M28.8 82.6A90 90 0 0069.7 53M28.8 9.4a85 85 0 0117.7 7.4m6.5 5.5c7 5.3 14 12.7 18.5 22.3m2.8 9.3a56.7 56.7 0 012.3 25M92 65a99.9 99.9 0 00-15-13M37.2 37.2a80 80 0 0131.5 10.2M9.4 39c4.1-.8 11-1.8 19.4-1.8\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "width": "97",
      "height": "92",
      "viewBox": "0 0 97 92",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});