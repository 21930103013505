define("ember-svg-jar/inlined/sharing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.4 11.4a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4zM13.5 18c.5.3 1.2.4 1.8.4.4 0 1.9-.5 3.6-1l4-1.4.6-.1c.6 0 1.2.3 1.4.9.3.8 0 1.7-.7 2a25.9 25.9 0 01-9.8 3.3c-1.8 0-6.5-1.7-8.9-2.7\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5.4 13.8c.8-.3 1.8-.6 3-.6 2.5 0 2.5 2 5 1.8 2.5-.2 4.9 0 5.3 2.4M2.2 19.6h3.2v-5.8H2.2c-.7 0-1.2.6-1.2 1.2v3.3c0 .7.5 1.3 1.2 1.3z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "26",
      "height": "23",
      "viewBox": "0 0 26 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});