define("ember-svg-jar/inlined/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M87.7 52.7c-.5-4.6-2.4-8.7-5.7-12a20 20 0 00-15.7-6.5c-2 .1-4 .6-6 .9l.4-2.3a84.3 84.3 0 018-21.4L74 1.8c.3-.5.4-1 .6-1.6l-.2-.2c-.4.2-.9.3-1.2.6C70.5 2.4 67.8 4 65.4 6a52 52 0 00-16.2 26A79 79 0 0046 52.8c0 2.1 0 4.2.4 6.3 1.7 8.6 8.4 15 17.2 16.6 9.6 1.7 19.5-4 22.8-13.2.7-1.9 1-3.8 1.4-5.8v-3.4l-.1-.6zM18.2 34.4l-4 .7V35l.1-.5A81.5 81.5 0 0122 12.7l6-11 .6-1.5-.3-.2-1.2.6c-3 2.1-6.1 4-9 6.5-6.7 5.6-11 13-13.8 21.2C1.7 35.8.3 43.7 0 51.7a26 26 0 001 9.8 20.4 20.4 0 0016.6 14.2c6.4 1 12.1-.6 17-4.8a20.9 20.9 0 00-16.4-36.5z\" fill=\"#13142F\"/>",
    "attrs": {
      "width": "88",
      "height": "77",
      "viewBox": "0 0 88 77",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});