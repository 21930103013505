define("ember-svg-jar/inlined/burn-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M59.2 22.9a39.4 39.4 0 1020.5 38.6M65 72.9c2-3.3 3.5-7.1 4-11.2m-57.3-7.5l-.2 3.4a29 29 0 0046.7 22.8M17.4 40a29 29 0 00-3 5m40-12.8a28.7 28.7 0 00-29.3.9\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M40.4 42.2l9.7 15.4-9.7 15.3-9.7-15.3 9.7-15.4zM71.5 1s.9 7.1-9.6 18.6a30.2 30.2 0 00-8.8 20.7c0 8.3 5 21.8 20 21.8 15.2 0 24-8.9 24-24.4 0-11.1-5.3-17.5-6.8-19 0 0-.2 8.3-6.8 7.6-6.6-.7-4.3-9-3.5-12.2C81.4 9 77.6 1.2 71.6 1h0z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M70.8 23.2s-9.5 6.2-8.6 18.8c.3 4.4 3.4 9 6.9 10.3 11.1 4.6 16.7-3.2 16.7-8.5 0-3.3-1.3-6.7-5.8-8.6-6.1-2.6-8.8-3.8-9.2-12v0z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "98",
      "height": "98",
      "viewBox": "0 0 98 98",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});