define("ember-svg-jar/inlined/telegram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 9.1c.2-.4.6-.6 1-.8l4.8-1.8a37364.7 37364.7 0 0117-6.5c.7 0 1.2.4 1.2 1.1l-.2 1.6A8202 8202 0 0120 19.4c-.3.5-.7.7-1.2.5-.3 0-.5-.1-.7-.3L13 15.8l-.2-.1-.2.2-2.6 2.5c-.3.2-.6.4-1 .4l.1-1 .3-4.4c0-.1 0-.3.2-.3l10-9 .2-.4-.4-.1-.5.2-12.4 7.8h-.3L.9 10c-.4-.1-.7-.3-.9-.6V9z\" fill=\"url(#a)\"/><defs><linearGradient id=\"a\" x1=\".4\" y1=\"10\" x2=\"24\" y2=\"10\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "20",
      "viewBox": "0 0 24 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});