define("ember-cli-flash/flash/object", ["exports", "@ember/object/evented", "@ember/object", "@ember/runloop", "ember-cli-flash/utils/computed"], function (_exports, _evented, _object, _runloop, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Note:
  // To avoid https://github.com/poteto/ember-cli-flash/issues/341 from happening, this class can't simply be called Object
  let FlashObject = (_dec = (0, _computed.guidFor)('message').readOnly(), (_class = class FlashObject extends _object.default.extend(_evented.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "exitTimer", null);

      _defineProperty(this, "exiting", false);

      _defineProperty(this, "isExitable", true);

      _defineProperty(this, "initializedTime", null);

      _initializerDefineProperty(this, "_guid", _descriptor, this);
    }

    init() {
      super.init(...arguments);

      if (this.sticky) {
        return;
      }

      this.timerTask();

      this._setInitializedTime();
    }

    destroyMessage() {
      this._cancelTimer();

      if (this.exitTaskInstance) {
        (0, _runloop.cancel)(this.exitTaskInstance);

        this._teardown();
      } else {
        this.exitTimerTask();
      }
    }

    exitMessage() {
      if (!this.isExitable) {
        return;
      }

      this.exitTimerTask();
      this.trigger('didExitMessage');
    }

    willDestroy() {
      if (this.onDestroy) {
        this.onDestroy();
      }

      this._cancelTimer();

      this._cancelTimer('exitTaskInstance');

      super.willDestroy(...arguments);
    }

    preventExit() {
      (0, _object.set)(this, 'isExitable', false);
    }

    allowExit() {
      (0, _object.set)(this, 'isExitable', true);

      this._checkIfShouldExit();
    }

    timerTask() {
      if (!this.timeout) {
        return;
      }

      const timerTaskInstance = (0, _runloop.later)(() => {
        this.exitMessage();
      }, this.timeout);
      (0, _object.set)(this, 'timerTaskInstance', timerTaskInstance);
    }

    exitTimerTask() {
      if (this.isDestroyed) {
        return;
      }

      (0, _object.set)(this, 'exiting', true);

      if (this.extendedTimeout) {
        let exitTaskInstance = (0, _runloop.later)(() => {
          this._teardown();
        }, this.extendedTimeout);
        (0, _object.set)(this, 'exitTaskInstance', exitTaskInstance);
      } else {
        this._teardown();
      }
    }

    _setInitializedTime() {
      let currentTime = new Date().getTime();
      (0, _object.set)(this, 'initializedTime', currentTime);
      return this.initializedTime;
    }

    _getElapsedTime() {
      let currentTime = new Date().getTime();
      return currentTime - this.initializedTime;
    }

    _cancelTimer() {
      let taskName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'timerTaskInstance';

      if (this[taskName]) {
        (0, _runloop.cancel)(this[taskName]);
      }
    }

    _checkIfShouldExit() {
      if (this._getElapsedTime() >= this.timeout && !this.sticky) {
        this._cancelTimer();

        this.exitMessage();
      }
    }

    _teardown() {
      const queue = this.flashService?.queue;

      if (queue) {
        queue.removeObject(this);
      }

      this.destroy();
      this.trigger('didDestroyMessage');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_guid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FlashObject;
});