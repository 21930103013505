define("ember-svg-jar/inlined/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 12V3c0-1.1.9-2 2-2h10a2 2 0 012 2v9m3 2v5a2 2 0 01-2 2H3a2 2 0 01-2-2v-5c0-1.1.9-2 2-2h4c.5 0 1 .4 1 1v1c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-1c0-.6.4-1 1-1h4a2 2 0 012 2zM9 4.9h4M9 8.4h4\" stroke=\"#B2B3B6\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});