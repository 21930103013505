define("ember-svg-jar/inlined/coingecko-orig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path d=\"M24 12a12 12 0 11-24 0 12 12 0 0124 0z\" fill=\"#FAE988\"/><path d=\"M18 7.7l-2.6-1v-.1h-.1c-.6-1.2-2-1.4-3.3-1-1-.3-2.1-.3-3.1-.1C4.4 6.7 3.7 9 3.6 11.6c-.3 2.8-.4 4.8-1.5 7.2a6.6 6.6 0 00.3.4 11.8 11.8 0 0011.7 4.6c-.5-3.6 2.6-7 4.3-8.7l1.4-1.4c2.4-2.2 3-4.7-1.7-6z\" fill=\"#8CC63F\"/><path d=\"M19.1 11.3c0 .3-.2.5-.5.5a.5.5 0 110-1c.3 0 .5.2.5.5z\" fill=\"#00000F\"/><path d=\"M12.6 9A2.3 2.3 0 118 9a2.3 2.3 0 014.6 0z\" fill=\"#fff\"/><path d=\"M12 9a1.6 1.6 0 11-3.3 0 1.6 1.6 0 013.2 0zm9 3.4a8 8 0 01-2 1.3l-2.3.8c-.8.2-1.6.4-2.4.3-.8 0-1.6-.4-2.1-1 .6.4 1.4.6 2.1.6a10.3 10.3 0 004.6-.9c.7-.3 1.4-.6 2-1.1z\" fill=\"#00000F\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});