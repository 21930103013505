define("ember-svg-jar/inlined/wave-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 1c1.6 0 1.6 2 3.3 2 1.6 0 1.6-2 3.2-2s1.6 2 3.3 2c1.6 0 1.6-2 3.2-2s1.6 2 3.3 2c1.6 0 1.6-2 3.2-2\" stroke=\"url(#a)\" stroke-width=\"1.5\" stroke-linecap=\"round\"/><path d=\"M1 7c1.6 0 1.6 2 3.3 2 1.6 0 1.6-2 3.2-2s1.6 2 3.3 2c1.6 0 1.6-2 3.2-2 1.7 0 1.7 2 3.3 2 1.6 0 1.6-2 3.2-2\" stroke=\"url(#b)\" stroke-width=\"1.5\" stroke-linecap=\"round\"/><path d=\"M1 13c1.6 0 1.6 2 3.3 2 1.6 0 1.6-2 3.2-2s1.6 2 3.3 2c1.6 0 1.6-2 3.2-2 1.7 0 1.7 2 3.3 2 1.6 0 1.6-2 3.2-2\" stroke=\"url(#c)\" stroke-width=\"1.5\" stroke-linecap=\"round\"/><defs><linearGradient id=\"a\" x1=\"1.3\" y1=\"2\" x2=\"20.5\" y2=\"2\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient><linearGradient id=\"b\" x1=\"1.3\" y1=\"8\" x2=\"20.5\" y2=\"8\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient><linearGradient id=\"c\" x1=\"1.3\" y1=\"14\" x2=\"20.5\" y2=\"14\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "22",
      "height": "16",
      "viewBox": "0 0 22 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});