define("ember-svg-jar/inlined/header-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2 18c6.6-4 14-8 22-11.2 4.8-2 16.5-7.7 20-3 1.7 2.5-1.2 10.7 4.6 11.7 6.6 1.2 12.1-8.5 19.1-7.2 2 .3 2.2 2 4.5 2.2 4.2.4 7.3-3 11.1-3 3 .1 4 2 6.5 2.6 4 1 6.7-.3 10.5-1 3-.5 4.9-.2 8-.1 4.5.2 7.6-.8 11.8-1 3.8 0 5.7 1.3 9 1.8 5.2.8 11.3-.4 16.4-1 5.7-.7 9.4 0 15 .8 6.6.9 13 .7 19.6.2 13.8-.9 92.7-.5 125.9-1\" stroke=\"url(#a)\" stroke-width=\"3\" stroke-linecap=\"round\"/><defs><linearGradient id=\"a\" x1=\"6.9\" y1=\"10\" x2=\"285\" y2=\"10\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#EF655D\"/><stop offset=\"1\" stop-color=\"#8149BF\"/></linearGradient></defs>",
    "attrs": {
      "width": "40",
      "height": "20",
      "viewBox": "0 0 308 20",
      "preserveAspectRatio": "none",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});