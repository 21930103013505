define("ember-svg-jar/inlined/coingecko", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24 12a12 12 0 11-24 0 12 12 0 0124 0z\" fill=\"#542D6C\"/><path d=\"M18 7.7c-.8-.2-1.7-.6-2.6-1l-.1-.2c-.6-1.1-2-1.3-3.3-.9-1-.2-2.1-.3-3.1 0-4.5 1.2-5.2 3.4-5.3 6-.3 2.8-.4 4.9-1.5 7.2l.3.4C4.7 22.6 9.5 24.9 14 24h.2c-.5-3.6 2.6-7 4.3-8.8l1.4-1.4c2.4-2.1 3-4.7-1.7-6z\" fill=\"url(#a)\"/><path d=\"M19.1 11.4c0 .2-.2.5-.5.5a.5.5 0 01-.5-.5c0-.3.2-.5.5-.5s.5.2.5.5z\" fill=\"#00000F\"/><path d=\"M12.6 9A2.3 2.3 0 118 9a2.3 2.3 0 014.6 0z\" fill=\"#fff\"/><path d=\"M12 9a1.6 1.6 0 11-3.3 0 1.6 1.6 0 013.2 0zm9 3.5a8 8 0 01-2 1.2c-.8.4-1.5.7-2.3.9-.8.2-1.6.3-2.4.2-.8 0-1.6-.3-2.1-1 .6.5 1.4.6 2.1.6a10.3 10.3 0 004.6-.8c.7-.3 1.4-.7 2-1.2z\" fill=\"#00000F\"/><defs><linearGradient id=\"a\" x1=\"12\" y1=\"25.1\" x2=\"14\" y2=\"6\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});