define("ember-svg-jar/inlined/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.2 20.4l-6.6-6.7a2 2 0 010-2.8l9.3-9.3a2 2 0 011.6-.6l6 .7a2 2 0 011.8 1.8l.7 6a2 2 0 01-.6 1.6l-9.3 9.3a2 2 0 01-2.9 0h0z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14 10.2a2.2 2.2 0 100-4.4 2.2 2.2 0 000 4.4z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});