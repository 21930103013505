define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"32\" height=\"2.9\" rx=\"1.5\" fill=\"#fff\"/><rect y=\"14.5\" width=\"32\" height=\"2.9\" rx=\"1.5\" fill=\"#fff\"/><rect x=\"16\" y=\"29.1\" width=\"16\" height=\"2.9\" rx=\"1.5\" fill=\"#fff\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});