define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "about.dao": "YashaDao",
    "about.dao.hint": "A vast community of like-minded individuals, where everyone's opinion matters.",
    "about.ecosystem": "YASHA Ecosystem",
    "about.incubator": "Incubator",
    "about.incubator.hint": "Ecosystem-wide support as well as guidance and advice from the Yasha DAO.",
    "about.launchpad": "Launchpad",
    "about.launchpad.hint": "A TGE platform for unique and vetted projects, governed by the community.",
    "buy": "How to Buy",
    "buy.desc": "<p class=\"section-text\">YASHA currently runs only on the Ethereum Network, which means it can be best purchased and sold through <b>UniSwap</b></p><p class=\"section-text\">YASHA is a decentralized experiment project. As such we always recommend the use of DEXs. If you choose to utilize a CEX instead, remember to research it first to ensure it is both safe and secure.</p>",
    "buy.guide": "Quick Start Guide",
    "buy.guide.connectUniswap": "Connect your wallet to UniSwap",
    "buy.guide.connectUniswap.desc": "Connect your wallet to UniSwap by clicking ‘Connect wallet’ and selecting MetaMask",
    "buy.guide.createWallet": "Create MetaMask wallet",
    "buy.guide.createWallet.desc": "Create a MetaMask Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive your $YASHA tokens",
    "buy.guide.sendEth": "Send ETH to your wallet",
    "buy.guide.sendEth.desc": "You can buy Ethereum (ETH) directly on MetaMask or transfer it to your MetaMask Wallet from exchanges like Bitmart, Ethereum, etc",
    "buy.guide.swap": "Swap ETH for YASHA",
    "buy.guide.swap.desc": "You can start swapping as soon as you have ETH available! Press ‘Select a token’ and enter the token address or search for it on the tokens list.",
    "cl.about": "About",
    "cl.buy": "Swap for YASHA",
    "cl.buy.dextools": "Buy on Dextools",
    "cl.buy.uniswap": "Buy on UniSwap",
    "cl.chart": "Chart",
    "cl.chart.view": "View Chart",
    "cl.connectWallet": "Connect Wallet",
    "cl.dextools": "Dextools",
    "cl.disconnectWallet": "Disconnect Wallet",
    "cl.disconnectWallet.address": "Disconnect {address}",
    "cl.getHelp": "Get Help",
    "cl.getHelp.hint": "Having problems registering for this contribution? <a href=\"{link}\" target=\"_blank\">Join our Telegram</a>, an administrator will always be there to help",
    "cl.home": "Home",
    "cl.joinTelegram": "Join Telegram",
    "cl.loading": "Loading...",
    "cl.logoTitle": "YASHA",
    "cl.projects": "Projects",
    "cl.projects.submit": "Submit a Project",
    "cl.roadmap": "Roadmap",
    "cl.staking": "Staking",
    "cl.title": "YASHA - Next-Gen Launchpad",
    "footer.audits": "Audits",
    "footer.banner": "Want to launch your project with <strong>YASHA?</strong>",
    "footer.copyright": "@Copyright <strong>YASHA</strong> 2022. all rights reserved",
    "footer.general": "General",
    "footer.general.apply": "Apply for YASHA",
    "footer.general.privacy": "Privacy Policy",
    "footer.general.staking": "Staking",
    "footer.general.stakingTiers": "Staking Tiers",
    "footer.general.terms": "Terms of Use",
    "footer.help": "Help",
    "footer.help.ecosystem": "YASHA Ecosystem",
    "footer.help.faq": "FAQ",
    "footer.help.participate": "Participate in TGEs",
    "footer.incubator": "Bringing a community driven incubator and launchpad to the space. Yasha holders vote for projects to be incubated and launched on YashaPAD.",
    "footer.token": "Token",
    "footer.token.coingecko": "Coingecko",
    "footer.token.coinmarketcap": "CoinMarketCap",
    "footer.token.dextools": "DexTools",
    "footer.token.uniswap": "Uniswap",
    "home.hero": "Next-Gen Launchpad",
    "home.hero.hint": "Early access to vetted projects that drive the future of DeFi",
    "home.stats.burned": "Tokens Burned",
    "home.stats.marketcap": "Marketcap",
    "home.stats.price": "Price",
    "home.stats.raised": "Total Funds Raised",
    "home.tabs.about": "About",
    "home.tabs.dao-voting": "DAO-Voting",
    "home.tabs.docs": "Docs",
    "home.tabs.faq": "Faq",
    "home.tabs.projects": "Projects",
    "home.tabs.staking": "Staking",
    "home.tabs.tiers": "Tiers",
    "mint.benefits": "NFT Benefits",
    "mint.benefits.community": "Community",
    "mint.benefits.community.hint": "Support the community and token Liquidity",
    "mint.benefits.opportunities": "Opportunities",
    "mint.benefits.opportunities.hint": "Get access to rare opportunities",
    "mint.benefits.rewards": "Token Rewards",
    "mint.benefits.rewards.hint": "Stake an NFT and earn additional token rewards",
    "mint.collection": "NFT Collection",
    "mint.hero": "YASHA <span>NFTs</span>",
    "mint.hero.hint": "Get additional rewards from the YASHA ecosystem by minting and staking an NFT...",
    "mint.mint": "Mint NFTS",
    "mint.minted": "NFTS Minted",
    "mint.notConnected": "Wallet is not connected",
    "mint.status.active": "Mint is open for whitelist wallets only",
    "mint.status.notActive": "Mint is not active",
    "mint.status.public": "Public mint is open",
    "projects.access": "Access",
    "projects.authorizeTelegram": "Authorize your telegram account. We will collect only your telegram name & username",
    "projects.connectWallet": "Connect the wallet that contains your YASHA tokens",
    "projects.hardCap": "Hard Cap",
    "projects.launchedOn": "Launch On",
    "projects.minTokens": "YASHA Tokens",
    "projects.pool.type": "Subject to Review",
    "projects.poolDetails": "Pool Details",
    "projects.progress": "Progress",
    "projects.progress.ends": "Ends",
    "projects.raised": "Total Raised",
    "projects.registration.closed": "The registration window for this contribution is closed",
    "projects.registration.open": "Registration for this contribution is currently open. Follow the instructions below to apply",
    "projects.registration.upcoming": "Registration for this contribution has not yet started. Check back later or join us on telegram for more information",
    "projects.requirements": "Requirements",
    "projects.softCap": "Soft Cap",
    "projects.softHardCap": "Soft/Hard Cap",
    "projects.startAt": "Start At",
    "projects.states.closed": "Closed",
    "projects.states.closed.noContent": "There are no closed TGEs to display!",
    "projects.states.open": "Open",
    "projects.states.open.noContent": "There are no open TGEs to display!",
    "projects.states.upcoming": "Upcoming",
    "projects.states.upcoming.noContent": "There are no upcoming TGEs to display!",
    "projects.submitApplication": "Submit Application",
    "projects.supply": "Total Supply",
    "projects.tgeEnds": "TGE Ends",
    "projects.tgeStartEnd": "TGE Start/End",
    "projects.tgeStarts": "TGE Starts",
    "projects.title": "Token Generation Event",
    "projects.token": "Token",
    "projects.type": "Type",
    "projects.type.fair": "Fair Launch",
    "projects.type.stealth": "Stealth Launch",
    "staking.approve": "Approve Tokens",
    "staking.benefits": "Staking Benefits",
    "staking.benefits.presales": "Access",
    "staking.benefits.presales.hint": "Private access to upcoming TGEs",
    "staking.benefits.price-action": "Price Floor",
    "staking.benefits.price-action.hint": "Decrease circulating supply which pushes the token price higher",
    "staking.benefits.rewards": "ETH Rewards",
    "staking.benefits.rewards.hint": "Receive ETH rewards while holding",
    "staking.claim": "Claim",
    "staking.claiming": "Rewards",
    "staking.connectWallet": "Connect Wallet",
    "staking.eth.available": "Available ETH",
    "staking.eth.claim": "Claim ETH",
    "staking.help": "Problems staking YASHA?",
    "staking.help.hint": "Checkout our FAQ's section for common questions and solutions",
    "staking.hero": "Stake <span>YASHA</span>",
    "staking.hero.hint": "Get more from the YASHA ecosystem by staking your $YASHA tokens and earn rewards, along with many other benefits...",
    "staking.lockDuration": "Lock Duration",
    "staking.nfts": "NFTs",
    "staking.nfts.approve": "Approve NFT",
    "staking.nfts.available": "Available NFTs",
    "staking.nfts.boost": "NFT Booster",
    "staking.nfts.boost.hint": "Stake a YASHA nft and multiply your ETH rewards",
    "staking.nfts.stake": "Stake NFT",
    "staking.nfts.stake.select": "Select an NFT to stake",
    "staking.nfts.staked": "Staked NFTs",
    "staking.nfts.stakedTime": "NFT {unlocked, plural, =1 {unlocked} other {will unlock}}",
    "staking.nfts.unstake": "Unstake NFT",
    "staking.rewards": "Receive ETH Rewards",
    "staking.shares": "Staked Tokens",
    "staking.shares.total": "Supply Staked",
    "staking.stake": "Stake Tokens",
    "staking.stake.max": "MAX",
    "staking.staking": "Stake",
    "staking.tokensAvailable": "Balance",
    "staking.tokensAvailable.value": "{number} YASHA",
    "staking.unlock": "Unlock Tokens",
    "staking.unlock.period": "Tokens {unlocked, plural, =1 {were unlocked} other {will unlock}}",
    "staking.unstake": "Unstake Tokens",
    "staking.unstake.none": "There are no YASHA tokens to unstake",
    "staking.unstaking": "Unstake",
    "staking.unstaking.duration.180": "6 Months",
    "staking.unstaking.duration.30": "1 Month",
    "staking.unstaking.duration.360": "1 Year",
    "staking.unstaking.duration.60": "2 Months",
    "staking.unstaking.duration.90": "3 Months",
    "testimonials.title": "Testimonials",
    "tiers.gokenin": "Gokenin",
    "tiers.gokenin.hint": "Castle Lord",
    "tiers.joshu-daimyo": "Joshu Daimyo",
    "tiers.joshu-daimyo.hint": "Castle Lord",
    "tiers.koshogumi": "Koshogumi",
    "tiers.koshogumi.hint": "First Rank Cavalry",
    "tiers.koyakunin": "Koyakunin",
    "tiers.koyakunin.hint": "Gate Guard",
    "tiers.kunimochi": "Kunimochi",
    "tiers.kunimochi.hint": "Governor",
    "tiers.mujo-daimyo": "Mujo Daimyo",
    "tiers.mujo-daimyo.hint": "Lord",
    "tiers.nakakosho": "Nakakosho",
    "tiers.nakakosho.hint": "Stableman",
    "tiers.pool": "Pool",
    "tiers.pool.audit": "Subject To Audit",
    "tiers.pool.guaranteed": "Guaranteed",
    "tiers.rusuigumi": "Rusuigumi",
    "tiers.rusuigumi.hint": "Officer",
    "tiers.shogun": "Shogun",
    "tiers.shogun.hint": "Commander",
    "tiers.title": "Allocation Tiers",
    "tiers.tokensAmount": "Token Amount",
    "tiers.tomokosho": "Tomokosho",
    "tiers.tomokosho.hint": "Attendant",
    "tokenomics.burned": "Tokens Burned",
    "tokenomics.buy": "Buy",
    "tokenomics.liquidity": "Liquidity",
    "tokenomics.marketing": "Marketing",
    "tokenomics.rewards": "Rewards",
    "tokenomics.sell": "Sell",
    "tokenomics.title": "Tokenomics",
    "tokenomics.total-supply": "Total Supply"
  }]];
  _exports.default = _default;
});