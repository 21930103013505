define("ember-svg-jar/inlined/connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 9.2h3c1.6 0 3.1.7 4 1.7 1 1 1.8 2.5 1.7 4a6 6 0 01-5.8 6h-3M11.3 9.2h-3a5.8 5.8 0 00-5.8 5.9 5.6 5.6 0 005.7 5.7h3m7.4-5.8H9.7\" stroke=\"#fff\" stroke-width=\"2\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "29",
      "height": "30",
      "viewBox": "0 0 29 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});