define("ember-svg-jar/inlined/unlock-primary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.5 21h-10C2.1 21 1 20 1 18.5v-5C1 12.1 2 11 3.5 11h10c1.4 0 2.5 1 2.5 2.5v5c0 1.4-1 2.5-2.5 2.5zM3.5 6a5 5 0 015-5 5 5 0 015 5v5h-10m5 3.8v2.4\" stroke=\"#3ECCB0\" stroke-width=\"2\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "22",
      "viewBox": "0 0 17 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});