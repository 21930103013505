define("ember-svg-jar/inlined/crown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 11.5L5 7.6l2.2.6L9 5.9l1.8 2.3 2.1-.6-.9 4c0 .2-.3.5-.6.5H6.6a.7.7 0 01-.6-.6h0z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9 17A8 8 0 109 1a8 8 0 000 16z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});