define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.6 4.7l2.3-2.3c.3-.4 0-1.1-.5-1l-2.8.4c-1-1-2.3-1.8-3.8-1.8A4.8 4.8 0 0012 4.7l.3 1.8C8.7 6.4 5 5.7 3 2c-.3-.5-1-.5-1.1 0a10 10 0 006.5 12.3C7 15.5 3.4 16 .7 15.6c-.6 0-1 .6-.5 1 2.4 2 5.8 2.3 8.8 2.3 7.8 0 13.2-6.5 12.6-14.2z\" fill=\"url(#a)\"/><defs><linearGradient id=\"a\" x1=\".4\" y1=\"9.5\" x2=\"24\" y2=\"9.5\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "19",
      "viewBox": "0 0 24 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});