define("ember-svg-jar/inlined/twitter-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.7 4.5l2.2-2.3c.3-.3 0-1-.5-.9l-2.6.4C18.8.7 17.6 0 16 0a4.5 4.5 0 00-4 6.2C8.5 6 5 5.4 3 1.8c-.2-.4-.9-.4-1 0a9.5 9.5 0 006.2 11.7c-1.3 1.2-4.7 1.6-7.3 1.3-.6 0-1 .6-.5 1C2.7 17.6 6 18 8.7 18c7.4 0 12.6-6.2 12-13.5z\" fill=\"#56568F\"/>",
    "attrs": {
      "width": "23",
      "height": "18",
      "viewBox": "0 0 23 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});