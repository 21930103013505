define("ember-svg-jar/inlined/thumbs-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.5 16.4l.6.2c.5.3 1 .4 1.7.4h4.6c2 0 1.6-2.2 1.6-2.2 1.9-.9 1.1-2.3 1.1-2.3 1.8-1.7.2-2.8.2-2.8.9-1.7-.2-2.5-1-2.5h-4.8c.9-2.2.9-3.8 0-5.2C9.5.6 8 1 8 1.3c0 .3.6 2.1.4 2.8l-2 4.2v8h0z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.5 8.3H3a2 2 0 00-2 2v4c0 1.2.9 2 2 2h3.5v-8z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});