define("ember-svg-jar/inlined/etherscan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 11.4c0-.5.5-1 1-1h1.7c.6 0 1 .5 1 1V18l.7-.2c.4-.1.7-.5.7-.9v-8c0-.5.5-1 1-1h1.7c.6 0 1 .5 1 1v7.5l.9-.4c.3-.1.5-.4.5-.8V6.3c0-.5.5-1 1-1H18c.6 0 1 .4 1 1v7.3c1.5-1.1 3-2.4 4.2-4 .3-.4.4-1 .2-1.5A12 12 0 101.6 18c.3.5.8.8 1.4.7l1.2-.1c.5 0 .8-.4.8-.8v-6.4z\" fill=\"url(#a)\"/><path d=\"M5 21.7a12 12 0 0019-10.5c-4.4 6.5-12.5 9.6-19 10.5z\" fill=\"url(#b)\"/><defs><linearGradient id=\"a\" x1=\".4\" y1=\"9.4\" x2=\"23.4\" y2=\"9.4\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient><linearGradient id=\"b\" x1=\"5.3\" y1=\"17.6\" x2=\"24\" y2=\"17.6\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});