define("ember-svg-jar/inlined/mint-rare-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M81 75.7L71.8 81V11.6L81 6.3v69.4zm-18.4 0l9.2 5.3V11.6l-9.2-5.3v69.4z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M71.8 11.6l-9.2-5.3L71.8 1 81 6.3l-9.2 5.3zM50.2 75.7L41 81V49.2l9.2-5.4v31.9zm-18.4 0L41 81V49.2l-9.2-5.4v31.9z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M41 49.2l-9.2-5.4 9.2-5.3 9.2 5.3-9.2 5.4zM19.4 75.7L10.2 81V65.2l9.2-5.4v15.9zM1 75.7l9.2 5.3V65.2L1 59.8v15.9z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10.2 65.2L1 59.8l9.2-5.3 9.2 5.3-9.2 5.4zM19 34A73.3 73.3 0 0052.7 1\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M54.1 7.5L52.7 1l-6.5 1.4M10.3 46.8a9.3 9.3 0 100-18.6 9.3 9.3 0 000 18.6z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10.3 33.4l1.4 2.7 3 .4-2.2 2.1.5 3-2.7-1.4-2.6 1.4.5-3-2.2-2 3-.5 1.3-2.7z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "82",
      "height": "82",
      "viewBox": "0 0 82 82",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});