define("ember-svg-jar/inlined/mint-community", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M41 17.3A8.2 8.2 0 1041 1a8.2 8.2 0 000 16.3zm31.8 31.9a8.2 8.2 0 100-16.4 8.2 8.2 0 000 16.4zM63.5 67a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-45 0a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm45-45a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-45 0a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM41 81a8.2 8.2 0 100-16.3A8.2 8.2 0 0041 81zM9.2 49.2a8.2 8.2 0 100-16.4 8.2 8.2 0 000 16.4z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M71.8 32.9a31.6 31.6 0 00-6-11.8m-4.9-4.9a31.6 31.6 0 00-11.8-6M32.9 71.8a31.6 31.6 0 01-11.8-6m-4.9-4.9a31.6 31.6 0 01-6-11.8m22.7-38.9a31.6 31.6 0 00-11.8 6m-4.9 4.9a31.6 31.6 0 00-6 11.8M71.8 49a31.6 31.6 0 01-6 11.9m-4.9 4.9a31.6 31.6 0 01-11.8 6M54.9 49L41 57V41l13.9-8v16zm-27.8 0L41 57V41l-13.9-8v16z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M41 41l-13.9-8L41 25l13.9 8L41 41z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "82",
      "height": "82",
      "viewBox": "0 0 82 82",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});