define("ember-svg-jar/inlined/checked-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 5.33l2.042 2.355c.157.19.418.308.7.315a.961.961 0 00.722-.28L11 1\" stroke=\"#08dc7d\" stroke-width=\"1.3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "12",
      "height": "9",
      "viewBox": "0 0 12 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});