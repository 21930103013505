define("ember-svg-jar/inlined/staking-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M43 57.8l27.8 15V102L48.7 68.7M101 56.5L70.8 102V72.8L101 56.5z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M91.8 51.6l9.2 5-30.2 16.2-27.8-15m6.8-6.2l-8.1 4.3\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M70.8 2v61L40.6 46.6 70.8 2zM101 46.7L70.8 62.9V2L101 46.7zM44.3 86.5c0 4.8-1.1 8.6-3.2 11.3-.6.9-1.4 1.6-2.3 2.2l-1.7 1c-3.8 1.8-8.9 1.3-14.4-2A47.9 47.9 0 011 61.5C1 54.8 3.3 50 7 47.6c1.3-.7 2.7-1.2 4.3-1.5 3.3-.4 7.2.5 11.4 2.9a48 48 0 0121.6 37.5zM22.7 54.9v4.5M6 64.4l4 2.3m12.7 26.5v-4.6m16.5-5l-3.9-2.3\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M28.6 69.7l-6 4.3 7.7 13.6\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M51.4 82.4c0 6.5-2.1 11.2-5.6 13.6l-1.5.9-5.5 3.1c.9-.6 1.7-1.3 2.3-2.2 2-2.7 3.3-6.5 3.3-11.3A48 48 0 0022.7 49c-4.2-2.4-8-3.3-11.4-2.9-1.5.3-3 .8-4.2 1.5l7.5-4.3c3.9-2.2 9.2-1.8 15.2 1.6a48 48 0 0121.6 37.5z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "width": "103",
      "height": "104",
      "viewBox": "0 0 103 104",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});