define("ember-svg-jar/inlined/box-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13 33.9c4-9.4 11.5-17 21-20.9m30.1 0c9.4 4 17 11.5 20.9 20.9M13 64.1c4 9.4 11.5 17 21 20.9m30.1 0c9.4-4 17-11.5 20.9-20.9\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M32 18l2-5-5-1.9M18 66l-5-2-1.9 5M66 80l-2 5 5 1.9M80 32l5 2 1.9-5M49 17.4A8.2 8.2 0 1049 1a8.2 8.2 0 000 16.4zM9.2 57.2a8.2 8.2 0 100-16.4 8.2 8.2 0 000 16.4zM49 97a8.2 8.2 0 100-16.4A8.2 8.2 0 0049 97zm39.8-39.8a8.2 8.2 0 100-16.4 8.2 8.2 0 000 16.4zM42.4 32.5l6.6-3.8 17.6 10.2L49 49 31.4 38.9l4.8-2.8\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M66.6 49V38.9L49 49v20.3l17.6-10.1v-2.7M49 69.3L31.4 59.2V38.9L49 49v20.3z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "98",
      "height": "98",
      "viewBox": "0 0 98 98",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});