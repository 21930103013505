define("ember-svg-jar/inlined/coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 16l2.6-1.5m-2-1.1l2.6 1.5a6 6 0 005.4 0c1.5-.9 1.5-2.3 0-3.2l-1.2-.7c-1.5-.8-1.5-2.2 0-3.1a6 6 0 015.4 0l2.6 1.5m-2-1.1L27 6.8\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M19 21.8c10 0 18-4.7 18-10.4S29 1 19 1 1 5.7 1 11.4s8 10.4 18 10.4z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M37 19c0 2.7-1.8 5.3-5.3 7.4-7 4-18.4 4-25.4 0-3.4-2-5.1-4.4-5.3-7\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M37 11.4v15.2c0 2.7-1.8 5.3-5.3 7.4-7 4-18.4 4-25.4 0-3.4-2-5.1-4.4-5.3-7V11.8\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});