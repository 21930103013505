define("ember-svg-jar/inlined/hand-ethereum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M72.5 58.9l13.8-13.7a5.7 5.7 0 017.7-.3c2.3 2 2.7 5.5.8 7.9L79.7 71.5c-4.4 5.3-7.9 6.9-12.7 6.9H39.4c-5 0-8.3 4.5-9 5.7\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M49.5 66.7h17.3a6 6 0 000-12H43.7S34.9 50 25 54.2a21.4 21.4 0 00-12 12.5m28.5 0h2.7\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16 68.4L28.6 81a6.7 6.7 0 010 9.6l-5 5a2 2 0 01-2.8 0L1.4 76.2a2 2 0 010-2.8l5-5a6.7 6.7 0 019.6 0v0zm23.2-15.3l-12-16.9L48.4 1l21.2 35.2-13 18.5\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M27.2 36.2l21.2 8.6 21.2-8.6m-21.2-8v5.7m0-21.6v9.2\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "97",
      "height": "97",
      "viewBox": "0 0 97 97",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});