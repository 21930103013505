define("ember-svg-jar/inlined/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M59.5 60a75.6 75.6 0 01-17.2 8.4c-4 1.4-6.6 1.9-6.6 1.9l-14-14s.5-2.6 1.9-6.6a75.8 75.8 0 018.3-17.2c4-6 9.3-12.1 16.5-17.3A74.4 74.4 0 0186.2 1c2.7-.2 5 2 4.8 4.8-.5 7.4-3 22.4-14.2 37.8a65.6 65.6 0 01-17.3 16.5z\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><path d=\"M21.7 56.3l-9.3 5.4a2 2 0 00-.4 3L27.2 80c1 1 2.5.7 3.1-.4l5.4-9.3-14-14zm33.6 30.3l-3.8 3.8a2 2 0 01-3.3-.8l-6-21.2a75.6 75.6 0 0017.3-8.3l-2 21.7c-.1 1.8-1 3.5-2.2 4.8zM23.6 49.7l-21.2-6a2 2 0 01-.8-3.2l3.8-3.8c1.3-1.3 3-2 4.8-2.2l21.7-2a75.8 75.8 0 00-8.3 17.2zm33.7-5.9a9.1 9.1 0 100-18.2 9.1 9.1 0 000 18.2zM85 29.2L62.9 7\" stroke=\"#fff\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "width": "92",
      "height": "92",
      "viewBox": "0 0 92 92",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});