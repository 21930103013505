define("ember-svg-jar/inlined/rings-gradient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.01 7.01C7.5 3.61 10.43 1 13.96 1 17.85 1 21 4.15 21 8.03c0 3.53-2.61 6.46-6.02 6.95m.08-1.01c0 3.88-3.15 7.03-7.03 7.03C4.15 21 1 17.85 1 13.97c0-3.88 3.15-7.04 7.03-7.04 3.88 0 7.03 3.15 7.03 7.03v.01z\" stroke=\"url(#a)\" stroke-width=\"1.5\" stroke-linecap=\"round\"/><defs><linearGradient id=\"a\" x1=\"1.349\" y1=\"11\" x2=\"21\" y2=\"11\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});