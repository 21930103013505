define("ember-svg-jar/inlined/arrow-right-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\" fill=\"#198754\"><path opacity=\".6\" d=\"M15.5 8h-14C.7 8 0 7.3 0 6.5S.7 5 1.5 5h14c.8 0 1.5.7 1.5 1.5S16.3 8 15.5 8z\"/><path d=\"M10.5 13c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l3.9-3.9-3.9-4C8.9 2 8.9 1 9.4.4c.5-.6 1.5-.6 2.1 0l5 5c.6.6.6 1.5 0 2.1l-5 5c-.2.4-.6.5-1 .5z\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h17v13H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "17",
      "height": "13",
      "viewBox": "0 0 17 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});