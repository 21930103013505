define("ember-svg-jar/inlined/fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.8 17c0 1.6.3 3.5 1.1 5 1.5 2.8 6 2 6.5-1.2.9-7.3-3.9-18.6 7.5-19.8-1.5 4.9.9 9.6 5.3 12.1 10.6 5.7 7 21.2-4.5 23.4C7.7 39.7-3.4 26.7 2.8 17zM29.3 1S28 3.8 32.8 7.7c4.8 4 4.2 8.1 4.2 8.1M22.6 24.4s-.9 6.2-7.3 5.6\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});