define("ember-svg-jar/inlined/staking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 17A8 8 0 109 1a8 8 0 000 16z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M8.3 6.2L6 8.2a1 1 0 000 1.5l2.2 2.1c.4.4 1 .4 1.4 0l2.1-2c.4-.5.4-1.1 0-1.5l-2-2.1a1 1 0 00-1.4 0z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});