define("ember-svg-jar/inlined/card-in-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 21.7H3.3c-1.3 0-2.4-1-2.4-2.3v-16C1 2 2 1 3.4 1h27.2C32 1 33 2 33 3.4V19m0-11.2H1M4.9 13h4.7m-4.7 3.6h8.4M26 22.7l2 2 2-2m-2 2v-8.4\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M20.4 18.3l2-2 2 2m-2-2v8.4\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "34",
      "height": "26",
      "viewBox": "0 0 34 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});