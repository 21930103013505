define("ember-svg-jar/inlined/star-gradient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 0l2.8 5.6 6.2.9-4.5 4.4 1 6.1L9 14.1 3.4 17l1-6.1L0 6.5l6.2-.9L9 0z\" fill=\"url(#a)\"/><defs><linearGradient id=\"a\" x1=\".3\" y1=\"8.5\" x2=\"18\" y2=\"8.5\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});