define("ember-svg-jar/inlined/gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M31 13.8H3c-1.2 0-2-.8-2-1.9V9.8c0-1.1.8-2 2-2h28c1.2 0 2 .9 2 2v2.1c0 1-.8 2-2 2z\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M27.4 25.3H6.6A2.6 2.6 0 014 22.7v-8.9h26v9c0 1.4-1.2 2.5-2.6 2.5zM17 7.8s2.5-3.8 3.6-5.1c1-1.4 3.4-2.4 5.3-1.1 2.5 1.7 1.2 6-2.7 6.2H17zm0 0l-3.5-5.1C12.5 1.4 10 .3 8 1.6c-2.3 1.7-1 6 3 6.2h6z\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M19.3 13.8h-4.5v11.5h4.5V13.8z\" stroke=\"#B2B3B6\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "34",
      "height": "26",
      "viewBox": "0 0 34 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});