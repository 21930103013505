define("ember-svg-jar/inlined/dextools-orig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 27.7l5-2.9-4.3-2.5-.7.4-.7-.4-4.4 2.5 5.1 3zM17 3l-5-3-5 3 4.3 2.4.7-.4.7.4L17.1 3zm2.2 1.2L15 6.7l3.2 1.9L0 19v2l4.8 2.7L9 21.2l-3.2-1.8L24 8.9v-2l-4.8-2.7zM24 12v8.8l-1.5.9-3.3 1.9L15 21l-3.3-2 3.2-1.8 3.3 1.9 1.6-.9v-3.8l.6-.4 3.6-2zM0 15.7V7l1.5-1 3.3-1.8L9 6.7l3.4 1.9-3.3 1.9-3.3-2-1.6 1v3.7l-.6.4L0 15.7z\" fill=\"#1A8E9D\"/>",
    "attrs": {
      "width": "24",
      "height": "28",
      "viewBox": "0 0 24 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});