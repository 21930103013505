define("ember-svg-jar/inlined/circles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.6 17a5.6 5.6 0 100-11.2 5.6 5.6 0 000 11.2z\" stroke=\"#666\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5.9 5.8A5.5 5.5 0 0111.4 1C14.5 1 17 3.5 17 6.6c0 2.8-2.1 5.2-4.9 5.5\" stroke=\"#666\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});