define("ember-svg-jar/inlined/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M6 8.1c2 0 3.6-1.6 3.6-3.6S8 1 6 1 2.4 2.6 2.4 4.5c0 2 1.6 3.6 3.6 3.6z\" stroke=\"#56568f\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1 14a5 5 0 015-5 5 5 0 015 5\" stroke=\"#56568f\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M14.9 9.4a3 3 0 003-2.9 3 3 0 00-3-2.9 3 3 0 00-3 3 3 3 0 003 2.8z\" stroke=\"#56568f\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14.6 10.5c1.2-.4 2.5-.2 3.5.4 1 .7 1.6 1.9 1.6 3.1\" stroke=\"#56568f\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "21",
      "height": "15",
      "viewBox": "0 0 21 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});