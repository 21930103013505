define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M8.7 11.1c3 0 5.5-2.2 5.5-5S11.8 1 8.7 1c-3 0-5.5 2.3-5.5 5 0 2.9 2.4 5.1 5.5 5.1z\" stroke=\"#56568F\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1 21c0-4 3.5-7.1 7.8-7.1s7.8 3.2 7.8 7.1\" stroke=\"#56568F\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "22",
      "viewBox": "0 0 18 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});