define("ember-svg-jar/inlined/checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.848 0l1.448 1.448L8.2 12.544l.007.008L6.759 14l-.008-.007-.007.007-1.448-1.448.006-.008L0 7.241l1.448-1.448 5.303 5.303L17.848 0z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "20",
      "height": "14",
      "viewBox": "0 0 20 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});