define("ember-svg-jar/inlined/article", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 8v6a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3h6l7 7z\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M17 8h-4.8c-1.2 0-2.1-1-2.1-2.2V1M6.7 9.8H4m6 3.2H4\" stroke=\"#797C82\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});