define("ember-svg-jar/inlined/box-gradient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21 6.77l-10 5.77L1 6.77 11 1l10 5.77zm-10 5.77v8.45l10-5.77V6.77l-10 5.77zM1 6.77v8.46L11 21v-8.45L1 6.77z\" stroke=\"url(#a)\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"a\" x1=\"1.349\" y1=\"11\" x2=\"21\" y2=\"11\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8149BF\"/><stop offset=\"1\" stop-color=\"#EF655D\"/></linearGradient></defs>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});