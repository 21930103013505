define("ember-svg-jar/inlined/instagram-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 4a4 4 0 014.1-4h10.4a4 4 0 014.1 4v10a4 4 0 01-4.1 4H4a4 4 0 01-4-4V4zm9.3 3a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2c0-1.1-.9-2-2-2zM5.2 9a4 4 0 014.1-4 4 4 0 014.1 4 4 4 0 01-4.1 4 4 4 0 01-4.1-4zM15 4.5c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .5 1 1 1z\" fill=\"#56568F\"/>",
    "attrs": {
      "width": "19",
      "height": "18",
      "viewBox": "0 0 19 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});