define("ember-svg-jar/inlined/telegram-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.6 8.2c.2-.4.5-.6.9-.7l4.3-1.7A34004.8 34004.8 0 0121.1 0c.6 0 1.1.3 1.1 1L22 2.4a7329.5 7329.5 0 01-3.3 15.1c-.3.4-.6.6-1.1.5-.2-.1-.5-.2-.6-.4-1.6-1-3.1-2.2-4.7-3.3l-.2-.2-.1.2-2.4 2.2c-.2.3-.5.4-.9.4v-1l.4-3.9.1-.3 9-8 .2-.4H18l-.4.1-11.2 7h-.3L1.4 9C1 8.9.8 8.8.6 8.5v-.3z\" fill=\"#56568F\"/>",
    "attrs": {
      "width": "23",
      "height": "18",
      "viewBox": "0 0 23 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});